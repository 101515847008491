import * as React from "react"
import { graphql, useStaticQuery } from "gatsby"

import { css } from "../styled"
import { ANIMATION_DELAY } from "../../utils/constants"

import * as S from "./navigation.css"
import { ICollectionNodes } from "../../utils/useCollectionNodes"

const globalWindow: any = typeof window !== "undefined" ? window : {}

const getYears = (arr: INode[]) =>
  arr.reduce((prev: Array<string | number>, { node }: INode, index: number) => {
    const date = new Date(node.frontmatter.fullDate)
    const year = date.getUTCFullYear()

    if (index === 0) {
      prev.push("now")
    }

    if (prev.indexOf(year) === -1) {
      prev.push(year)
    }

    return prev
  }, [])

const Navigation: React.FC<ICollectionNodes> = ({ getAllNodes = () => [] }) => {
  const {
    allMarkdownRemark: { edges },
  }: INav = useStaticQuery(query)

  const years = getYears(edges)
  const [currentYear, setCurrentYear] = React.useState("now")

  const onScroll = () => {
    const activeYear = getAllNodes()
      .reverse()
      .find(item => item.getBoundingClientRect().top < globalWindow.innerHeight)
    const id = activeYear && activeYear.getAttribute("id")

    return setCurrentYear(id || "now")
  }

  React.useEffect(() => {
    onScroll()
    document.addEventListener("scroll", onScroll)
    return () => document.removeEventListener("scroll", onScroll)
  }, [])

  const goTo = (year: number | string) => {
    const el = document.getElementById(`${year}`)

    if (el) {
      return el.scrollIntoView({
        behavior: "smooth",
      })
    }

    return null
  }

  return (
    <S.Wrapper>
      <div>
        {years.map((year: string | number, index: number) => (
          <S.Year
            active={currentYear == year}
            color="grey"
            key={year}
            css={css`
              animation: ${(index + 1) * 150}ms ${ANIMATION_DELAY}ms
                ${S.slideRight} forwards;
            `}
          >
            <button onClick={() => goTo(year)}>
              <S.Dash active={currentYear == year} />

              {year}
            </button>
          </S.Year>
        ))}
      </div>
    </S.Wrapper>
  )
}

export default Navigation

interface INode {
  node: {
    frontmatter: {
      fullDate: string
    }
  }
}

interface INav {
  allMarkdownRemark: {
    edges: INode[]
  }
}

const query = graphql`
  query Navigation {
    allMarkdownRemark(
      sort: { fields: frontmatter___fullDate, order: DESC }
      filter: { frontmatter: { ignoreMe: { ne: true }, future: { ne: true } } }
    ) {
      edges {
        node {
          frontmatter {
            fullDate
          }
        }
      }
    }
  }
`
