import * as React from "react"
import { graphql, useStaticQuery } from "gatsby"
import { Spring } from "react-spring"
import ConfettiGenerator from "confetti-js"

import textByLine from "../../utils/textByLines"
import { ANIMATION_CONFIG, ANIMATION_DELAY } from "../../utils/constants"
import * as S from "./signup.css"
import Form from "./form"
import Success from "./success"
import BackgroundImages from "./backgroundImages"
import Link from "../UI/Link"
import logo from "../../images/logo.svg"
import Future from "../Future/future"

const Signup: React.FC = () => {
  const {
    contentYaml: { homepage: data },
  }: ISignUp = useStaticQuery(query)

  const [futureInView, setFutureInView] = React.useState(true)
  const containerRef = React.useRef<HTMLDivElement>(null)
  const titleArr = textByLine(data.title)
  const [successPage, setSuccessPage] = React.useState(false)

  React.useEffect(() => {
    const confettiSettings = {
      target: "confetti",
      rotate: true,
      max: 100,
      props: ["square", "triangle", "line"],
      colors: [[248, 189, 72], [0, 125, 247], [238, 38, 94], [200, 234, 222]],
    }
    const confetti = (ConfettiGenerator as any)(confettiSettings)

    if (successPage) {
      confetti.render()

      if (containerRef.current) {
        containerRef.current.scrollIntoView()
        window.scrollBy(0, -58)
      }
    }
    return () => confetti.clear()
  }, [successPage])

  return (
    <>
      {successPage && (
        <Future inView={futureInView} setInView={setFutureInView} />
      )}

      <S.Container id="now" ref={containerRef}>
        <Spring
          config={ANIMATION_CONFIG}
          to={{
            opacity: !futureInView && successPage ? 1 : 0,
            transform: `scale(${!futureInView && successPage ? 1 : 0.9})`,
          }}
        >
          {props => (
            <S.Confetti style={props}>
              <canvas id="confetti" />
            </S.Confetti>
          )}
        </Spring>

        <div>
          <BackgroundImages successPage={successPage} />

          {/* Form */}
          <Spring
            config={ANIMATION_CONFIG}
            delay={successPage ? 0 : ANIMATION_DELAY}
            from={{ opacity: 0, transform: "translateY(50px)" }}
            to={{
              opacity: successPage ? 0 : 1,
              transform: `translateY(${successPage ? 150 : 0}px)`,
            }}
          >
            {props => (
              <S.Section style={props}>
                <S.Wrapper>
                  <S.Logo>
                    <img src={logo} alt="Akasha" />
                  </S.Logo>
                  <h1>
                    {titleArr.map(e => (
                      <S.Title as="span" key={e}>
                        {e}
                      </S.Title>
                    ))}
                  </h1>

                  <Form onSuccess={() => setSuccessPage(true)} />

                  <Link to={data.ctaLink}>{data.cta}</Link>
                </S.Wrapper>
              </S.Section>
            )}
          </Spring>

          {/* Success page */}
          <Spring
            config={ANIMATION_CONFIG}
            from={{ opacity: 0, transform: "translateY(-150px)" }}
            to={{
              opacity: successPage ? 1 : 0,
              transform: `translateY(${successPage ? 0 : -150}px)`,
            }}
          >
            {props => (
              <Success
                futureInView={futureInView}
                style={{
                  ...props,
                  pointerEvents: successPage ? "auto" : "none",
                }}
              />
            )}
          </Spring>
        </div>
      </S.Container>
    </>
  )
}

export default Signup

interface ISignUp {
  contentYaml: {
    homepage: {
      title: string
      text: string
      cta: string
      ctaLink: string
    }
  }
}

const query = graphql`
  query SignUp {
    contentYaml {
      homepage {
        title
        text
        cta
        ctaLink
      }
    }
  }
`
