import styled, { css } from "../styled"
import { animated } from "react-spring"
import arrowSrc from "../../images/arrow.svg"

const MARGIN = 32
const CARD_WIDTH = 360
const CIRCLE_SIZE = 8

export const Wrapper = styled.section`
  display: flex;
  justify-content: center;
  position: relative;
  margin-top: -20vh;
`

export const Line = styled(animated.div)`
  width: 2px;
  height: 50vh;
  background: ${({ theme: { colors } }) => colors.blue.default};
  position: absolute;
  border-radius: 2px;
  z-index: 9;

  @media (max-width: 600px) {
    left: 0;
  }
`

export const BaseLine = styled.div`
  width: 2px;
  height: 100%;
  background: ${({ theme: { colors } }) => colors.grayscale.mediumLight};
  position: absolute;
  border-radius: 2px;
  opacity: 0.5;

  @media (max-width: 600px) {
    left: 0;
  }
`
