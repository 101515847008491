import styled, { createGlobalStyle } from "../styled"
import { animated } from "react-spring"
import { Circle as TimelineCircle } from "../Timeline/timeline.css"

export const Container = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
`

export const Circle = styled(TimelineCircle)`
  top: auto;
  bottom: -20vh;
`

export const DarkTheme = createGlobalStyle`
  html {
    background: #0f192c;
  }

  body {
    background: #000C20;

     &:after {
      content: "";
      display: block;
      position: absolute;
      left: 1.5em;
      right: 1.5em;
      top: 1.5em;
      height: 10vh;
      background: linear-gradient(
        #000C20,
        rgba(0,0,0,0) 80%
      );
      border-radius: 4px;
      z-index: 999;

      @media (max-width: 600px) {
        top: 0;
      }
    }
  }
`

export const Line = styled(animated.div)`
  width: 2px;
  height: 50vh;
  background: ${({ theme: { colors } }) => colors.blue.default};
  position: absolute;
  bottom: 0;
  border-radius: 2px;
  z-index: 9;
  margin-bottom: -20vh;

  @media (max-width: 600px) {
    left: 0;
  }
`
export const BaseLine = styled.div`
  width: 2px;
  height: 100%;
  background: ${({ theme: { colors } }) => colors.grayscale.mediumLight};
  position: absolute;
  bottom: 0;
  border-radius: 2px;
  opacity: 0.2;
  margin-bottom: -20vh;

  @media (max-width: 600px) {
    left: 0;
  }
`

export const Canvas = styled(animated.div)`
  position: absolute;
  left: 0;
  right: 0;

  canvas {
    width: 100%;
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
  }

  @media (max-width: 600px) {
    display: none;
  }
`
