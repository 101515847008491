import * as React from "react"
import { useSpring } from "react-spring"

import * as S from "./past.css"

import { ANIMATION_CONFIG, ANIMATION_DELAY } from "../../utils/constants"
import { WRAPPER_HEIGHT } from "../Signup/signup.css"

const globalWindow: any = typeof window !== "undefined" ? window : {}
const MIDDLE_VIEW = globalWindow.innerHeight / 2
const isMobile = globalWindow.innerWidth < 768

const ProgressLine: React.FC<any> = ({ getAllNodes }) => {
  const [props, set] = useSpring(() => ({
    height: 0,
    config: ANIMATION_CONFIG,
  }))

  const lineRef = React.useRef<HTMLDivElement>(null)
  const [maxLength, setMaxLength] = React.useState(1000)

  // setMaxLength
  React.useEffect(() => {
    const allNodes = getAllNodes()
    const lastItem = allNodes[allNodes.length - 1]
    const cardOffset = isMobile ? 12.2 * 16 : 8 * 16

    setMaxLength(
      lastItem.getBoundingClientRect().top -
        globalWindow.innerHeight +
        globalWindow.innerHeight * 0.2 +
        cardOffset
    )
  }, [])

  // on scroll
  const setHeight = () => {
    const { top } = lineRef!.current!.getBoundingClientRect()

    const offSettop = globalWindow!.pageYOffset + top

    const heightWithMin = Math.max(
      MIDDLE_VIEW - WRAPPER_HEIGHT / 2,
      globalWindow!.pageYOffset - offSettop + MIDDLE_VIEW + WRAPPER_HEIGHT / 2
    )

    const heightWithMax = Math.min(maxLength, heightWithMin)

    set({ height: heightWithMax })
  }

  // set events
  React.useEffect(() => {
    globalWindow!.addEventListener("scroll", setHeight)

    return () => globalWindow!.removeEventListener("scroll", setHeight)
  }, [maxLength])

  // animation delay
  React.useEffect(() => {
    setTimeout(setHeight, ANIMATION_DELAY)
  }, [])

  return (
    <>
      <S.Line ref={lineRef} style={props} />
      <S.BaseLine style={{ height: `${maxLength}px` }} />
    </>
  )
}

export default ProgressLine
