import * as React from "react"
import { graphql, useStaticQuery } from "gatsby"

import * as S from "./timeline.css"
import * as Templates from "./templates"
import { ICollectionNodes } from "../../utils/useCollectionNodes"

const Timeline: React.FC<
  ICollectionNodes & { future?: boolean; dark?: boolean }
> = ({ pushNode = () => {}, future = false, dark }) => {
  const {
    allMarkdownRemark: { edges },
  }: ITimeline = useStaticQuery(query)

  const dataFiltered = edges.filter(
    ({ node }) => !!node.frontmatter.future === future
  )

  return (
    <S.Grid id="grid" future={future}>
      <div />
      {dataFiltered.map(({ node }, index, arr) => {
        // Data things
        const { template, title, fullDate } = node.frontmatter
        const year = future ? undefined : new Date(fullDate).getUTCFullYear()

        if (template === "text") {
          return (
            <Templates.Text
              ref={(n: HTMLElement) => !future && pushNode(title, n)}
              key={title}
              data={node}
              id={year}
              dark={dark}
              future={future}
              style={{ zIndex: arr.length - index }}
            />
          )
        }

        if (template === "card") {
          return (
            <Templates.Card
              ref={(n: HTMLElement) => !future && pushNode(title, n)}
              key={title}
              data={node}
              id={year}
              dark={dark}
              future={future}
              style={{ zIndex: arr.length - index }}
            />
          )
        }

        if (template === "mosaic") {
          return (
            <Templates.Mosaic
              ref={(n: HTMLElement) => !future && pushNode(title, n)}
              key={title}
              data={node}
              id={year}
              dark={dark}
              future={future}
              style={{ zIndex: arr.length - index }}
            />
          )
        }

        return null
      })}
    </S.Grid>
  )
}

export default Timeline

export interface INode {
  frontmatter: {
    title: string
    fullDate: string
    shortDate: string
    template: string
    video?: string
    future?: boolean
    illustration: string
    illustrationOrder?: string
    image?: {
      childImageSharp: {
        fluid: any
      }
    }
  }
  html: string
}

interface ITimeline {
  allMarkdownRemark: {
    edges: Array<{
      node: INode
    }>
  }
}

const query = graphql`
  query Timeline {
    allMarkdownRemark(
      sort: { fields: frontmatter___fullDate, order: DESC }
      filter: { frontmatter: { ignoreMe: { ne: true } } }
    ) {
      edges {
        node {
          frontmatter {
            title
            fullDate
            shortDate
            template
            video
            illustration
            illustrationOrder
            future
            image {
              childImageSharp {
                fluid(maxWidth: 400) {
                  ...GatsbyImageSharpFluid_noBase64
                }
              }
            }
          }
          html
        }
      }
    }
  }
`
