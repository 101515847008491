import styled, { css, keyframes } from "../styled"
import * as T from "../Typography"

export const Wrapper = styled.nav`
  position: fixed;
  left: 3em;
  top: 20%;
  bottom: 20%;
  display: flex;
  z-index: 9999;
  transition: all ${({ theme: { transition } }) => transition.ease()};

  @media (max-width: 1050px) {
    left: -100%;
  }

  > div {
    margin: auto;
  }
`

export const slideRight = keyframes`
  to {
    opacity: 1;
    transform: translateX(0);
  }
`

export const Year = styled(T.Text)<{ active: boolean; css: any }>`
  margin-bottom: 0.5em;
  transition: color ${({ theme: { transition } }) => transition.cubic()};
  font-size: 0.75rem;
  opacity: 0;
  transform: translateX(-20px);

  ${({ active, theme }) =>
    active &&
    css`
      color: ${theme.colors.blue.default};
    `}

  button {
    color: inherit;
    text-transform: uppercase;
  }
`

export const Dash = styled.span<{ active: boolean }>`
  display: inline-block;
  min-width: 1em;
  height: 2px;
  background: ${({ theme }) => theme.colors.grayscale.default};
  margin-right: 0.5em;
  vertical-align: middle;
  border-radius: 2px;
  transition: all ${({ theme: { transition } }) => transition.cubic()};
  top: -2px;
  position: relative;

  ${({ active, theme }) =>
    active &&
    css`
      background: ${theme.colors.blue.default};
      min-width: 3em;
    `}
`
