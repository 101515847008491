import * as React from "react"
if (typeof window !== "undefined") {
  require("intersection-observer")
}

import * as S from "./future.css"
import ProgressLine from "./progressLine"
import Stars from "./stars"
import Timeline from "../Timeline/timeline"

interface IProp {
  inView: boolean
  setInView: (param: boolean) => void
}

const Future: React.FC<IProp> = ({ inView, setInView }) => {
  const containerRef = React.useRef<any>(null)

  const [containerHeight, setContainerHeight] = React.useState(0)

  React.useEffect(() => {
    const observer = new IntersectionObserver(
      entries => {
        setInView(entries[0].isIntersecting)
      },
      { rootMargin: "-30%" }
    )

    observer.observe(containerRef.current)

    setContainerHeight(containerRef.current.getBoundingClientRect().height)

    return () => observer.unobserve(containerRef.current)
  }, [])

  return (
    <>
      {inView && <S.DarkTheme />}

      <S.Container ref={containerRef}>
        <S.Circle />
        <Stars inView={inView} height={containerHeight} />
        <ProgressLine maxLength={containerHeight} />

        <Timeline dark future />
      </S.Container>
    </>
  )
}

export default Future
