import * as React from "react"

interface IProp {
  force?: number
  offsetComp?: number
}

const useParallax = ({ force = 1, offsetComp = 0 }: IProp) => {
  const [offset, setOffset] = React.useState(0)
  const [lastScrollY, setLastScrollY] = React.useState(0)
  const [ticking, setTicking] = React.useState(false)
  const parallaxRef = React.useRef<HTMLDivElement>(null)
  const bodyBounding = React.useRef(
    typeof document !== "undefined" && document.body.getBoundingClientRect()
  ).current

  const requestAnimation = () => {
    if (!ticking) {
      window.requestAnimationFrame(animation)

      setLastScrollY(window.scrollY)
      setTicking(true)
    }
  }

  const isDesktop = () => window.innerWidth >= 832 // 52em * 16px

  const animation = () => {
    if (!isDesktop()) {
      return setOffset(0)
    }

    const elementOffset =
      parallaxRef.current!.getBoundingClientRect()!.top -
      (bodyBounding ? bodyBounding.top : 0)
    const threshold = 10 / force! || 1
    const offSet = (lastScrollY - elementOffset * 0.9) / threshold + offsetComp

    setOffset(offSet)
    setTicking(false)
  }

  React.useEffect(() => {
    window.addEventListener("scroll", requestAnimation)

    return () => window.removeEventListener("scroll", requestAnimation)
  }, [])

  return { parallaxRef, offset }
}

export default useParallax
