import styled, { createGlobalStyle, css } from "../styled"
import { animated } from "react-spring"

import * as T from "../Typography"

export const BODY_OFFSET = 24
export const WRAPPER_HEIGHT = 360

export const Container = styled.div<{ ref: any }>`
  display: flex;
  min-height: 400px;
  height: 100vh;
  position: relative;

  > div {
    margin: auto;
  }
`

export const Section = styled(animated.section)`
  margin-bottom: 5em;
  display: flex;

  @media (max-width: 600px) {
    margin-top: 5em;
  }
`

export const Wrapper = styled.div<{ css?: any }>`
  max-width: 440px;
  text-align: center;
  margin: auto;

  @media (max-width: 600px) {
    max-width: 100%;
    text-align: left;
  }
`

export const Logo = styled.div`
  margin-bottom: 2.5em;

  @media (max-width: 600px) {
    display: none;
  }

  img {
    width: 100%;
    max-width: 8em;
    margin: 0 auto;
  }
`

export const Title = styled(T.Title)`
  display: block;

  &:last-child {
    margin-bottom: 1rem;
  }
`

export const Description = styled(T.Description)``

export const Form = styled.form`
  max-width: 90%;
  margin: auto;
  padding: 0.45rem 0.45rem 0.45rem 1rem;
  display: flex;
  margin-top: 2.3em;

  background: ${({ theme: { colors } }) => colors.grayscale.white};
  border: 1px solid ${({ theme: { colors } }) => colors.grayscale.mediumLight};
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.03);
  border-radius: 4px;

  @media (max-width: 600px) {
    max-width: 100%;
  }

  input {
    flex: 1;
    position: relative;
    top: 0.1em;
  }

  .normal-view {
    display: none;

    @media (min-width: 400px) {
      display: block;
    }
  }

  .small-view {
    display: block;

    @media (min-width: 400px) {
      display: none;
    }
  }
`
export const SocialLink = styled.a`
  color: ${({ theme: { colors } }) => colors.grayscale.default};
  margin: 1em 0.3em;
  transition: opacity ${({ theme: { transition } }) => transition.ease()};

  &:hover {
    opacity: 0.8;
  }
`

export const MessageError = styled.p`
  min-height: 25px;
  color: ${({ theme }) => theme.colors.red.default};
  margin-top: 0.4em;
  margin-bottom: 1em;
`

export const TopImage = styled(animated.div)`
  position: absolute;
  left: 10em;
  top: 15vh;
  width: 20vw;

  @media (max-width: 1250px) {
    left: 1.5em;
  }

  @media (max-width: 600px) {
    display: none;
  }
`

export const RightImage = styled(animated.div)`
  right: 1.5em;
  position: absolute;
  bottom: 0;
  width: 35vw;
  max-height: 100vh;

  @media (max-width: 600px) {
    display: none;
  }
`

export const Confetti = styled(animated.div)`
  position: absolute;
  left: 0;
  right: 0;

  canvas {
    width: 100%;
    height: 100%;
  }

  @media (max-width: 600px) {
    display: none;
  }

  &:after {
    content: "";
    display: block;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    height: 30%;
    background: linear-gradient(
      rgba(255, 2552, 255, 0),
      ${({ theme: { colors } }) => colors.grayscale.light} 80%
    );
  }
`

export const SuccessSection = styled(Section)<{ futureInView: boolean }>`
  position: absolute;
  top: 25vh;

  @media (min-width: 600px) {
    left: 0;
    right: 0;
  }

  ${({ futureInView }) =>
    futureInView &&
    css`
      ${Title} {
        color: #fff;
      }

      ${Confetti}:after {
        display: none;
      }
    `}
`
