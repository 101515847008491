import * as React from "react"
import { useSpring } from "react-spring"

import * as S from "./future.css"

import { ANIMATION_CONFIG, ANIMATION_DELAY } from "../../utils/constants"

const globalWindow: any = typeof window !== "undefined" ? window : {}
const OFFSET = globalWindow.innerHeight * 0.3

const ProgressLine: React.FC<any> = ({ maxLength }) => {
  const [props, set] = useSpring(() => ({
    height: 0,
    config: ANIMATION_CONFIG,
  }))

  // on scroll
  const setHeight = () => {
    const height = maxLength - globalWindow!.pageYOffset
    const withMinHeight = Math.max(OFFSET, height)

    set({ height: withMinHeight })
  }

  // set events
  React.useEffect(() => {
    globalWindow!.addEventListener("scroll", setHeight)

    return () => globalWindow!.removeEventListener("scroll", setHeight)
  }, [maxLength])

  // animation delay
  React.useEffect(() => {
    setTimeout(setHeight, ANIMATION_DELAY)
  }, [])

  return (
    <>
      <S.Line style={props} />
      <S.BaseLine style={{ height: `${maxLength + OFFSET}px` }} />
    </>
  )
}

export default ProgressLine
