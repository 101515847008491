import { FluidObject } from "gatsby-image"

interface IOriginalName {
  originalName: string
}

export type FluidObjectWithName = FluidObject & IOriginalName

interface INode {
  edges: Array<{
    node: {
      fluid: FluidObjectWithName
    }
  }>
}

export default (data: INode) =>
  (imageName: string): FluidObject | null => {
    const obj = data.edges.find(
      ({ node }) => node.fluid.originalName === imageName
    )

    if (obj) {
      return obj.node.fluid
    }

    return null
  }
