import * as React from "react"

import Layout from "../components/Layout/layout"
import Signup from "../components/Signup/signup"
import Past from "../components/Past/past"
import Navigation from "../components/Navigation/navigation"
import useCollectionNodes, {
  ICollectionNodes,
} from "../utils/useCollectionNodes"
import SEO from "../components/seo"

const IndexPage = () => {
  const { getAllNodes, pushNode }: ICollectionNodes = useCollectionNodes()

  if (typeof window !== "undefined") {
    window.scrollTo(0, 0)
  }

  return (
    <Layout>
      <SEO />
      <Navigation getAllNodes={getAllNodes} pushNode={pushNode} />
      <Signup />
      <Past pushNode={pushNode} getAllNodes={getAllNodes} />
    </Layout>
  )
}

export default IndexPage
