import * as React from "react"
import ConfettiGenerator from "confetti-js"
import { Spring } from "react-spring"

import useParallax from "../../utils/useParallax"
import * as S from "./future.css"
import { ANIMATION_CONFIG, ANIMATION_DELAY } from "../../utils/constants"

const starsSettings = {
  animate: false,
  max: 100,
  size: 1,
  props: ["circle"],
  colors: [
    [255, 255, 255],
    [138, 148, 166],
    [46, 55, 71],
    [83, 98, 124],
    [229, 231, 235],
    [247, 247, 247],
  ],
}

const Stars: React.FC<{ height: number; inView: boolean }> = ({
  height,
  inView,
}) => {
  const { parallaxRef, offset } = useParallax({ force: 1.7 })

  React.useEffect(() => {
    const starOne = (ConfettiGenerator as any)({
      ...starsSettings,
      height,
      target: "stars-1",
    })

    const starTwo = (ConfettiGenerator as any)({
      ...starsSettings,
      height,
      size: 0.4,
      target: "stars-2",
    })

    starOne.render()
    starTwo.render()

    return () => {
      starOne.clear()
      starTwo.clear()
    }
  }, [height])

  return (
    <Spring config={ANIMATION_CONFIG} to={{ opacity: inView ? 1 : 0 }}>
      {props => (
        <S.Canvas style={props} ref={parallaxRef}>
          <canvas style={{ opacity: 0.7 }} id="stars-1" />
          <canvas
            style={{ transform: `translateY(${offset}px)` }}
            id="stars-2"
          />
        </S.Canvas>
      )}
    </Spring>
  )
}

export default Stars
