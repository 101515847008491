import * as React from "react"
import { graphql, useStaticQuery } from "gatsby"

import * as S from "./signup.css"
import Button from "../UI/Button"
import loadingSrc from "../../images/loading.svg"
import formatError from "../../utils/formatError"
import { URL, EMAIL_REGEX } from "../../utils/constants"

const Form: React.FC<IProp> = ({ onSuccess }) => {
  const {
    contentYaml: { homepage: data },
  }: IForm = useStaticQuery(query)

  const [loading, setLoading] = React.useState(false)
  const [error, setError] = React.useState<React.ReactNode>()
  const [disabled, setDisabled] = React.useState(true)
  const [email, setEmail] = React.useState("")

  const onChange = (e: any) => setEmail(e.target.value)

  const onSubmit = async (e: any) => {
    e.preventDefault()

    if (!EMAIL_REGEX.test(email)) {
      return null
    }

    try {
      setLoading(true)

      const data = {
        body: JSON.stringify({ email }),
        method: "POST",
      }

      const result = await fetch(URL, data)
      const response = await result.json()

      if (response.status === 400) {
        setError(formatError(response.title, email))
      } else {
        onSuccess()
      }
    } catch (err) {
      setError(formatError())
    }

    return setLoading(false)
  }

  React.useEffect(() => {
    setDisabled(!EMAIL_REGEX.test(email))
    setError("")
  }, [email])

  const buttonText = loading ? (
    <img src={loadingSrc} alt="loading" />
  ) : (
    data.form.button
  )

  return (
    <>
      <S.Form onSubmit={onSubmit}>
        <input
          name="email"
          type="email"
          value={email}
          placeholder={data.form.placeholder}
          onChange={onChange}
        />

        <Button onClick={onSubmit} type="button" disabled={loading || disabled}>
          <span className="normal-view">{buttonText}</span>
          <span className="small-view">Send</span>
        </Button>
      </S.Form>
      <S.MessageError>{error}</S.MessageError>
    </>
  )
}

export default Form

interface IProp {
  onSuccess: () => void
}

interface IForm {
  contentYaml: {
    homepage: {
      form: {
        placeholder: string
        button: string
        success: {
          title: string
          text: string
        }
      }
    }
  }
}

const query = graphql`
  query Form {
    contentYaml {
      homepage {
        form {
          placeholder
          button
          success {
            title
            text
          }
        }
      }
    }
  }
`
