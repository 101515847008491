import * as React from "react"
import { graphql, useStaticQuery } from "gatsby"
import { Spring } from "react-spring"
import Img from "gatsby-image"

import findImage, { FluidObjectWithName } from "../../utils/findImage"
import { ANIMATION_CONFIG } from "../../utils/constants"
import * as S from "./signup.css"
import useParallax from "../../utils/useParallax"

const BackgroundImages: React.FC<IProp> = ({ successPage }) => {
  const { allImageSharp }: ISignUp = useStaticQuery(query)

  const getImage = findImage(allImageSharp)
  const { parallaxRef, offset } = useParallax({ force: 1.5 })

  const initTo = {
    opacity: successPage ? 0 : 1,
    transform: `scale(${successPage ? 0.93 : 1}) translateY(${
      successPage ? 50 : 0
    }px)`,
  }
  const successTo = {
    opacity: !successPage ? 0 : 1,
    transform: `scale(${!successPage ? 0.93 : 1}) translateY(${
      !successPage ? -50 : 0
    }px)`,
  }

  return (
    <div ref={parallaxRef}>
      {/* Image on top */}
      <Spring config={ANIMATION_CONFIG} to={initTo}>
        {props => (
          <S.TopImage style={props}>
            <Img
              style={{ transform: `translateY(${offset}px)` }}
              fluid={getImage("homepage-sign.png")!}
            />
          </S.TopImage>
        )}
      </Spring>

      <Spring config={ANIMATION_CONFIG} to={successTo}>
        {props => (
          <S.TopImage style={props}>
            <Img
              style={{ transform: `translateY(${offset}px)` }}
              fluid={getImage("homepage-top.png")!}
            />
          </S.TopImage>
        )}
      </Spring>
      {/* end Image on top */}

      {/* Succes and main images */}
      <Spring config={ANIMATION_CONFIG} to={initTo}>
        {props => (
          <S.RightImage style={props}>
            <Img
              style={{ transform: `translateY(${offset * 1.3}px)` }}
              fluid={getImage("homepage-right.png")!}
            />
          </S.RightImage>
        )}
      </Spring>

      <Spring config={ANIMATION_CONFIG} to={successTo}>
        {props => (
          <S.RightImage style={props}>
            <Img
              style={{ transform: `translateY(${offset * 1.3}px)` }}
              fluid={getImage("homepage-success.png")!}
            />
          </S.RightImage>
        )}
      </Spring>
      {/* end Succes and main images */}
    </div>
  )
}

export default BackgroundImages

const query = graphql`
  query SignUpImages {
    allImageSharp(
      filter: {
        fluid: {
          originalName: {
            in: [
              "homepage-sign.png"
              "homepage-right.png"
              "homepage-success.png"
              "homepage-top.png"
            ]
          }
        }
      }
    ) {
      edges {
        node {
          fluid {
            originalName
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
    }
  }
`

interface ISignUp {
  contentYaml: {
    homepage: {
      title: string
      text: string
    }
  }
  allImageSharp: {
    edges: Array<{
      node: {
        fluid: FluidObjectWithName
      }
    }>
  }
}

interface IProp {
  successPage: boolean
}
