import * as React from "react"
import { graphql, useStaticQuery } from "gatsby"

import * as S from "./past.css"
import { ICollectionNodes } from "../../utils/useCollectionNodes"
import ProgressLine from "./progressLine"
import Timeline from "../Timeline/timeline"
import { Circle } from "../Timeline/timeline.css"

const Past: React.FC<ICollectionNodes> = ({ getAllNodes, pushNode }) => {
  return (
    <S.Wrapper>
      <Circle />
      <ProgressLine getAllNodes={getAllNodes} />

      <Timeline pushNode={pushNode} getAllNodes={getAllNodes} />
    </S.Wrapper>
  )
}

export default Past
