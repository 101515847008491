import * as React from "react"
import { graphql, useStaticQuery } from "gatsby"

import * as S from "./signup.css"
import textByLine from "../../utils/textByLines"
import getSocialIcons from "../../utils/getSocialIcons"

interface ISuccess {
  contentYaml: {
    homepage: {
      form: {
        success: {
          title: string
          text: string
        }
      }
      social: Array<{
        label: string
        src: string
      }>
    }
  }
}

const Success: React.FC<{ [key: string]: any }> = ({
  futureInView,
  ...props
}) => {
  const {
    contentYaml: { homepage: data },
  }: ISuccess = useStaticQuery(query)

  const titleArr = textByLine(data.form.success.title)
  const textArr = textByLine(data.form.success.text)

  return (
    <S.SuccessSection futureInView={futureInView} {...props}>
      <S.Wrapper css={{ marginTop: "8vw" }}>
        <h1>
          {titleArr.map(e => (
            <S.Title as="span" key={e}>
              {e}
            </S.Title>
          ))}
        </h1>

        {textArr.map(e => (
          <S.Description color="grey" key={e}>
            {e}
          </S.Description>
        ))}

        {data.social.map(({ label, src }) => (
          <S.SocialLink key={src} href={src} target="_blank" rel="noreferrer">
            {getSocialIcons(label)}
          </S.SocialLink>
        ))}
      </S.Wrapper>
    </S.SuccessSection>
  )
}

export default Success

const query = graphql`
  query Success {
    contentYaml {
      homepage {
        form {
          success {
            title
            text
          }
        }
        social {
          label
          src
        }
      }
    }
  }
`
